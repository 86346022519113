/** @jsx jsx */
import { Link } from 'gatsby'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { Box, Button, Container, Text, Heading, jsx } from 'theme-ui'

const ErrorCheckoutPage = ({ requestId }) => {
  const { cart } = useCart()
  const { number } = cart

  return (
    <Box>
      <Container pt={[3, 7, 9]} pb={[5, 6, 7]}>
        <Box sx={{ textAlign: 'center' }}>
          <Heading as="h1" variant="title2" mb={5}>
            {'There was an issue with checkout.'}
          </Heading>
          <Box mb={5}>
            <Text as="p" variant="body1" mb={2}>
              {'Please '}
              <strong>
                <Link to="/cart/" sx={{ textDecoration: 'underline' }}>
                  {'try again'}
                </Link>
              </strong>
              {" and make sure your cart isn't empty."}
            </Text>
            <Text as="p" variant="body1" mb={2}>
              {'If it happens again, let know us at '}
              <strong>
                <a href="mailto:support@makerwine.com">
                  {'support@makerwine.com'}
                </a>
              </strong>
              {'.'}
            </Text>
            {number && (
              <Text as="p" variant="body2">
                {'Providing the following information would be really helpful:'}
                <br />
                <strong>{number}</strong>
              </Text>
            )}
          </Box>
          <Button as={Link} to="/shop/">
            Shop wines
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default ErrorCheckoutPage
