import React from 'react'
import { navigate } from 'gatsby'
import CheckoutPage from '~/components/Checkout/Page'
import Layout from '~/components/Layout'
import Metadata from '~/components/Metadata'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'

const Checkout = () => {
  const translate = useTranslate()

  const handleTunneledBack = () => navigate(-1)

  return (
    <Layout tunneled={true} handleTunneledBack={handleTunneledBack}>
      <Metadata title={translate('checkout.page_title')} noindex={true} />
      <CheckoutPage />
    </Layout>
  )
}

export default Checkout
