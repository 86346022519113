/** @jsx jsx */
import { useEffect, useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { navigate } from 'gatsby'
import { useCart, useCheckout } from '@chordcommerce/gatsby-theme-autonomy'
import { Box, Container, jsx, Spinner } from 'theme-ui'
import ErrorCheckoutPage from '~/components/Error/Checkout/Page'

const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PK_KEY, {
  stripeAccount: process.env.GATSBY_STRIPE_CONNECTED_ACCOUNT,
})

const CheckoutPage = () => {
  const { forgetCart } = useCart()
  const { prepareCheckout } = useCheckout()
  const [checkout, setCheckout] = useState(null)
  const [error, setError] = useState(false)

  const handleComplete = async function (checkout, cart) {
    const { embeddedCheckout } = checkout

    checkout.unmount()

    navigate(
      `/order/confirmation/${cart.number}/${embeddedCheckout.checkoutSessionId}`
    )
    forgetCart()
  }

  useEffect(() => {
    const initCheckout = async () => {
      try {
        const cart = await prepareCheckout()

        const stripe = await stripePromise

        // Must have STRIPE_EMBED_CHECKOUT=enabled in OMS store.
        const checkoutObj = await stripe.initEmbeddedCheckout({
          clientSecret: cart.clientSecret || cart.checkoutSessionId,
          onComplete: () => handleComplete(checkoutObj, cart),
        })

        setCheckout(checkoutObj)
      } catch (error) {
        setError(error)
        console.error(error)
      }
    }

    if (!checkout) initCheckout()
  }, []) // eslint-disable-line

  useEffect(() => {
    checkout?.mount('#checkout')

    return () => checkout?.destroy()
  }, [checkout])

  return (
    <Box pt={5} pb={12}>
      <Container>
        <div id="checkout"></div>
        {!checkout && !error && (
          <Box py={10} sx={{ textAlign: 'center' }}>
            <Spinner
              color="brandTertiary"
              size="100"
              sx={{
                '@media (prefers-reduced-motion)': {
                  circle: {
                    animationIterationCount: 8,
                  },
                },
              }}
            />
          </Box>
        )}
        {error && <ErrorCheckoutPage />}
      </Container>
    </Box>
  )
}

export default CheckoutPage
